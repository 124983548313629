import { Button } from "reactstrap";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { CLIENT_ID } from "../../../config";

const GoogleLoginComponent = ({ btnText, className, onSuccess }) => {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <MyCustomButton {...{ btnText, className, onSuccess }} />
    </GoogleOAuthProvider>
  );
};

const MyCustomButton = ({ btnText, className, onSuccess }) => {
  /**
   *
   * @param {Object} response - google res object
   * @param {Boolean} failure - failure -> true or false
   */
  const _responseGoogle = (response, failure) => {
    try {
      if (failure) {
        console.log(`google failure:${failure} res: `, response);
        return;
      }

      console.log(`google failure:${failure} res: `, response);

      if (response && !failure) {
        const payload = {
          ...response,
          accessToken: response.access_token,
        };
        console.log("Response form Google", payload);
        onSuccess(payload);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onClickGoogle = useGoogleLogin({
    onSuccess: (response) => _responseGoogle(response, false), // failure -> true or false
    onError: () => _responseGoogle(null, true), // failure -> true or false
  });

  return (
    <Button onClick={() => onClickGoogle()}>
      {btnText ? (
        btnText
      ) : (
        <>
          <img src={require("../../../assets/img/google.png")} alt="google" />
        </>
      )}
    </Button>
  );
};

export default GoogleLoginComponent;
