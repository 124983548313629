import React, {
  Suspense,
  lazy,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledDropdown,
} from "reactstrap";
import {
  capitalize,
  checkIsDateValid,
  convertIsoToUtc,
  debounce,
  deepClone,
  deleteLocalStorageData,
  errorHandler,
  formatCurrencyValue,
  formatOnlyDateMoment,
  getDateRangeValue,
  isRegularUser,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import InfoCard from "../components/infoCard";
import CustomCard from "../components/CustomCard";
import CustomTable from "../components/CustomTable";
import { useSelector } from "react-redux";
import {
  incomeHeaderAgentKeys,
  incomeListAccountingCompanyIncomeHeaderKeys,
} from "../../../config/stateConfig";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  agentFetchAllIncome,
  agentGetAllUnregisteredClient,
  agentUpdateIncome,
  companyGetListTeamsOfClient,
  deleteCompanyIncomeById,
  getAllListClients,
  signingCompanyFetchAllIncome,
  updateAgentIncomeForRecordPayment,
  updateCompanyIncomeForRecordPayment,
  updateSigningcompanyIncome,
} from "../../../http/http-calls";
import SignerPopover from "../components/common/popover";
import { agentClosingStatus } from "../../../config";
import Swal from "sweetalert2";
import AddNotesModal from "../components/add-notes-income-list-modal";
import AddIncomeModalCompany from "../components/add-income-modal-company";
import AddIncomeModalAgent from "../components/add-income-modal-agent";
import DownloadInvoiceModal from "../components/Modals/DownloadInvoiceModal";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import useScreenWidth from "../components/HelpModule/useScreenWidth";
import { useHistory } from "react-router-dom";
import SvgIcons from "../components/SvgIcons";

const AgentCompanyIncomeFilter = lazy(() =>
  import("../components/AgentCompanyIncomeFilter")
);
const AgentCompanyFooterSection = lazy(() =>
  import("../components/AgentCompanyFooterSection")
);
const AgentCompanyIncomeCardDataFormat = lazy(() =>
  import("../components/AgentCompanyIncomeCardDataFormat")
);
const AgentCompanyIncomeDataFormat = lazy(() =>
  import("../components/AgentCompanyIncomeDataFormat")
);

const AgentCompanyIncomeListAccounting = (props) => {
  const userData = useSelector((state) => state.userData);
  const history = useHistory();

  const { isForMobile } = useScreenWidth();
  const [incomeList, setIncomeList] = useState([]);
  const [incomeTotalCount, setIncomeTotalCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [totalPendingDueAmount, setTotalPendingDueAmount] = useState(0);

  const incomeHeaderKeys =
    userData.type === "signingcompany"
      ? incomeListAccountingCompanyIncomeHeaderKeys
      : incomeHeaderAgentKeys;

  // eslint-disable-next-line no-unused-vars
  const [incomeCardHeaderKeys, setIncomeCardHeaderKeys] = useState([
    { id: "id", label: "id" },
    { id: "name", label: "Name" },
  ]);

  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 50,
    pageNumber: 1,
  });

  const [addNotesModal, setAddNotesModal] = useState({
    isOpen: false,
    data: null,
  });

  const [downloadInvoiceModal, setDownloadInvoiceModal] = useState({
    isOpen: false,
    data: null,
  });

  const [filterDropdownValues, setFilterDropdownValues] = useState({
    payment: [
      { name: "Pending Payment", value: "Pending" },
      { name: "Paid", value: "Paid" },
    ],
    clients: [],
    clientTeamsList: [],
    signingCompany: [],
    unregisterClientList: [],
  });

  const [addIncomeModalCompany, setAddIncomeModalCompany] = useState({
    isOpen: false,
    data: null,
  });

  const [addIncomeModalAgent, setAddIncomeModalAgent] = useState({
    isOpen: false,
    data: null,
  });

  const [upgradeAccountModal, setUpgradeAccountModal] = useState({
    isOpen: false,
    data: null,
  });

  const [filters, setFilters] = useState({
    payment: "",
    client: "",
    clientTeam: "",
    loanType: "",
    search: "",
  });

  const [dateRangeValue, setDateRangeValue] = useState(null);

  const [loading, setLoading] = useState({
    showTableLoading: false,
    markPaidLoading: false,
    deleteIncomeLoading: false,
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [incomeObj, setIncomeObj] = useState([]);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [checkDate, setCheckDate] = useState("");
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [clientOption, setClientOption] = useState([]);
  const [unregisterClientListOption, setUnregisterClientListOption] = useState(
    []
  );

  const [isOpenReactDatetime, setIsOpenReactDatetime] = useState(false);

  // manage loading
  const _manageLoading = (loaderName, value) => {
    const newLoadingState = { ...loading };
    newLoadingState[loaderName] = value;
    setLoading(newLoadingState);
  };

  // filter show on mobile view
  const filterShowMobile = () => {
    setIsClassAdded((prevIsClassAdded) => !prevIsClassAdded);
  };

  // fetch all company income
  const _companyFetchAllIncome = async (newPayload) => {
    _manageLoading("showTableLoading", true);
    const filterPayload = {};

    if (newPayload) {
      if (newPayload.client && newPayload.client.length) {
        filterPayload.clientId = newPayload.client;
      }
      if (newPayload.clientTeam && newPayload.clientTeam.length) {
        filterPayload.clientAssistantTeam = newPayload.clientTeam;
      }
      if (newPayload.payment && newPayload.payment.length) {
        filterPayload.status = newPayload.payment;
      }
      if (newPayload.dateRangeValue) {
        filterPayload.startDate = formatOnlyDateMoment(
          newPayload.dateRangeValue[0]
        );
        filterPayload.endDate = formatOnlyDateMoment(
          newPayload.dateRangeValue[1]
        );
      }
      if (newPayload.search && newPayload.search.length) {
        tableConfig.search = newPayload.search;
      } else {
        tableConfig.search = "";
      }
      tableConfig.skip = newPayload?.skip;
      tableConfig.limit = newPayload?.limit;
      tableConfig.pageNumber = newPayload?.pageNumber;
      tableConfig.filters = { ...filterPayload };
    }

    try {
      const res = await signingCompanyFetchAllIncome(tableConfig);
      setIncomeList(res.incomes);
      setIncomeTotalCount(res.totalCount);
      setTotalAmount(res.totalAmount);
      setTotalPaidAmount(res.totalPaidAmount || 0);
      setTotalPendingDueAmount(res.totalPendingDueAmount || 0);
      _manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      _manageLoading("showTableLoading", false);
    }
  };

  // get all unregistered client list for agent
  const _agentGetAllUnregisteredClient = async (data) => {
    let payload = {};
    if (data) {
      payload = { search: data };
    }
    try {
      const res = await agentGetAllUnregisteredClient(payload);
      const newFilterDropdownValues = deepClone(filterDropdownValues);
      newFilterDropdownValues.unregisterClientList = res?.clients || [];
      const options =
        res?.clients?.map((item) => ({
          value: item._id,
          label: item?._signingCompany
            ? item?.companyName
            : item?._client || item?._assistant
              ? item?.name?.full
              : "N/A",
        })) || [];
      setFilterDropdownValues(newFilterDropdownValues);
      setUnregisterClientListOption(options);
    } catch (error) {
      errorHandler(error);
    }
  };

  // fetch all income list for agent
  const _agentFetchAllIncome = async (newPayload) => {
    _manageLoading("showTableLoading", true);
    const filterPayload = {};

    if (newPayload) {
      if (newPayload.client && newPayload.client.length) {
        const selectedClient = filterDropdownValues.unregisterClientList.find(
          (each) => each._id === newPayload.client
        );
        if (selectedClient) {
          if (selectedClient.hasOwnProperty("_client")) {
            filterPayload.company = "unregisteredClient";
            filterPayload.clientId = selectedClient._client || undefined;
          } else if (selectedClient.hasOwnProperty("_signingCompany")) {
            filterPayload.company = selectedClient._signingCompany;
            filterPayload.clientId = selectedClient._client || undefined;
          } else {
            filterPayload["assistantId"] = selectedClient._assistant;
          }
        }
      }

      if (newPayload.payment && newPayload.payment.length) {
        filterPayload.status = newPayload.payment;
      }

      if (newPayload.dateRangeValue) {
        filterPayload.startDate = formatOnlyDateMoment(
          newPayload.dateRangeValue[0]
        );
        filterPayload.endDate = formatOnlyDateMoment(
          newPayload.dateRangeValue[1]
        );
      }

      if (newPayload.customDateRangeValue) {
        filterPayload.groupByUnit =
          newPayload.customDateRangeValue === "allTime" ? "year" : "day";
      }

      if (newPayload.search && newPayload.search.length) {
        tableConfig.search = newPayload.search;
      }

      tableConfig.filters = { ...filterPayload };
    } else {
      const { dateRangeValue, customDateRangeValue } = filters || {};
      if (dateRangeValue) {
        filterPayload.startDate = formatOnlyDateMoment(dateRangeValue[0]);
        filterPayload.endDate = formatOnlyDateMoment(dateRangeValue[1]);
      }

      if (customDateRangeValue) {
        filterPayload.groupByUnit =
          customDateRangeValue === "allTime" ? "year" : "day";
      }

      tableConfig.filters = { ...filterPayload };
      tableConfig.limit = newPayload?.limit;
      tableConfig.pageNumber = newPayload?.pageNumber;
      tableConfig.filters = { ...filterPayload };
    }

    try {
      const res = await agentFetchAllIncome(tableConfig);
      setIncomeList(res?.incomes || []);
      setIncomeTotalCount(res?.totalCount || 0);
      setTotalAmount(res?.totalAmount || 0);
      setTotalPaidAmount(res?.totalPaidAmount || 0);
      setTotalPendingDueAmount(res?.totalPendingDueAmount || 0);
      _manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      _manageLoading("showTableLoading", false);
    }
  };

  // get client list data for company
  const _getAllListClients = async (data) => {
    let payload = {};
    if (data) {
      payload = { search: data };
    }
    try {
      const res = await getAllListClients(payload);
      const newFilterDropdownValues = deepClone(filterDropdownValues);
      const options =
        res?.clients?.map((item) => ({
          value: item._id,
          label: item.companyName,
        })) || [];
      setFilterDropdownValues({
        ...newFilterDropdownValues,
        clients: res.clients,
      });
      setClientOption(options);
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  // function to save filter data in local storage
  const _persistFilter = () => {
    if (
      (filters &&
        (filters.payment ||
          filters.client ||
          filters.loanType ||
          (filters.search && filters.search.length))) ||
      dateRangeValue !== null ||
      tableConfig.pageNumber !== 0
    ) {
      let data = { filters, dateRangeValue, tableConfig };
      localStorage.setItem("income", JSON.stringify(data));
    } else {
      // localStorage.removeItem("income");
    }
  };

  // debounce the search fields
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _searchOnChange = useCallback(
    debounce((payload) => {
      _paginate(payload);
      // _persistFilter();
    }, 1000),
    []
  );

  // handle pagination
  const _paginate = (initialPayload) => {
    let pageNumber = initialPayload?.pageNumber
      ? initialPayload?.pageNumber
      : 1;
    let pageSize = 50;
    setTableConfig((prevTableConfig) => ({
      ...prevTableConfig,
      skip: (pageNumber - 1) * pageSize,
      limit: pageSize,
      pageNumber,
    }));

    // _persistFilter();
    if (userData.type === "signingcompany") {
      _companyFetchAllIncome({
        ...initialPayload,
        skip: (pageNumber - 1) * pageSize,
        limit: pageSize,
        pageNumber: pageNumber,
      });
    } else {
      _agentFetchAllIncome({
        ...initialPayload,
        skip: (pageNumber - 1) * pageSize,
        limit: pageSize,
        pageNumber: pageNumber,
      });
    }
  };

  // reset filter
  const _resetFilter = () => {
    const defaultFilters = {
      dateRangeValue: filters?.dateRangeValue,
      payment: "",
      client: "",
      clientTeam: "",
      loanType: "",
      search: "",
    };

    setFilters(defaultFilters);
    setDateRangeValue(null);
    _paginate({
      ...defaultFilters,
      customDateRangeValue: "month",
    });
    refreshFunc("company-accounting-income-responsive");
    deleteLocalStorageData("companyIncome");
  };

  // set default range value i.e month,week,day,year
  const _setDateRangeValue = (value = "month") => {
    let newFilters = {};
    let newPaginateFilters = {};

    switch (value) {
      case "day":
      case "week":
      case "month":
      case "year":
        newFilters = {
          dateRangeValue: getDateRangeValue(value),
          customDateRangeValue: value,
        };
        newPaginateFilters = {
          ...filters,
          dateRangeValue: getDateRangeValue(value),
          customDateRangeValue: value,
        };
        break;
      case "allTime":
        newFilters = { dateRangeValue: null, customDateRangeValue: value };
        newPaginateFilters = { ...filters, dateRangeValue: null };
        break;
      case "customDateRange":
        newFilters = { dateRangeValue: null, customDateRangeValue: value };
        newPaginateFilters = {
          ...filters,
          dateRangeValue: null,
          customDateRangeValue: value,
        };
        break;
      default:
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
    }

    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    _paginate(newPaginateFilters);
    // _persistFilter();
  };

  // handle on change date picker
  const _onChangeDatePicker = (dateRangeValue) => {
    setDateRangeValue(dateRangeValue);
    _paginate({ ...filters, dateRangeValue: dateRangeValue });
    // _persistFilter();
  };

  // handle filter on change
  const _filterOnChange = (type, value) => {
    // let newFilters = { ...filters };
    let newFilters = deepClone(filters);

    console.log("filtercchange", type, value, filters);

    switch (type) {
      case "dateRangeValue":
        newFilters = {
          ...newFilters,
          customDateRangeValue: value ? "customDateRange" : null,
          [type]: value,
        };
        break;

      case "search":
        newFilters = {
          ...newFilters,
          [type]: value,
          dateRangeValue: dateRangeValue,
        };
        _searchOnChange({
          ...newFilters,
          [type]: value,
          dateRangeValue: dateRangeValue,
        });
        break;

      default:
        newFilters = {
          ...newFilters,
          [type]: value,
          dateRangeValue: dateRangeValue,
        };
        if (type === "client") {
          newFilters["clientTeam"] = "";
          if (value?.length && userData.type === "signingcompany") {
            _getCompanyListTeamsOfClient(value);
          } else {
            filterDropdownValues["clientTeamsList"] = [];
          }
        }

        break;
    }

    setFilters(newFilters);
    if (type !== "search") {
      _paginate(newFilters);
    }
    // _persistFilter();
  };

  // add income modal for company
  const _onToggleAddIncomeModalCompany = (isOpen = false, data = null) => {
    setAddIncomeModalCompany({ isOpen, data });
  };

  // add income modal for agent
  const _onToggleAddIncomeModalAgent = (isOpen = false, data = null) => {
    setAddIncomeModalAgent({ isOpen, data });
  };

  //acount upgrade for agent
  const _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    setUpgradeAccountModal({ isOpen, data });
  };

  // add notes modal
  const _onToggleAddNotesModal = (isOpen = false, data = null) => {
    setAddNotesModal({ isOpen, data });
  };

  // download invoice modal
  const _onToggleDownloadInvoiceModal = (isOpen = false, data = null) => {
    setDownloadInvoiceModal({ isOpen, data });
  };

  // format data for the table
  const _dataFormat = (cell, row, header) => {
    return (
      <Suspense fallback={<></>}>
        <AgentCompanyIncomeDataFormat
          cell={cell}
          row={row}
          header={header}
          _onToggleAddNotesModal={_onToggleAddNotesModal}
          agentClosingStatus={agentClosingStatus}
          _removeIncomeAlert={_removeIncomeAlert}
          _onToggleDownloadInvoiceModal={_onToggleDownloadInvoiceModal}
          _onToggleAddIncomeModalCompany={_onToggleAddIncomeModalCompany}
          _onToggleAddIncomeModalAgent={_onToggleAddIncomeModalAgent}
          incomeObj={incomeObj}
          selectedRows={selectedRows}
          _onChangeFormField={_onChangeFormField}
          loading={loading}
          userType={userData?.type}
        />
      </Suspense>
    );
  };

  // update income to save note for company
  const _onSaveNotes = (id, notes) => {
    _manageLoading("showTableLoading", true);

    const updateIncome =
      userData?.type === "agent"
        ? agentUpdateIncome
        : updateSigningcompanyIncome;

    return new Promise((resolve, reject) => {
      updateIncome(id, { notes: notes?.trim() ? notes.trim() : "" })
        .then((res) => {
          if (userData?.type === "agent") {
            _agentFetchAllIncome();
          } else {
            _companyFetchAllIncome();
          }
          _manageLoading("showTableLoading", false);
          resolve(res);
        })
        .catch((error) => {
          _manageLoading("showTableLoading", false);
          reject(error);
        });
    });
  };

  // selected rows is getting as props to this function
  const _setSelectedRows = (selectedRows = []) => {
    // check for if due payment is not 0 or undefined
    selectedRows = selectedRows.filter(
      (each) => !(each?.duePayment === 0 || each?.duePayment === undefined)
    );
    // find for the id matched
    incomeObj.forEach((obj) => {
      let index = selectedRows.findIndex((each) => obj._id === each._id);
      if (index > -1) {
        selectedRows[index] = obj;
      }
      return selectedRows;
    });

    setSelectedRows(selectedRows);
    setIncomeObj(selectedRows);
  };

  // calculate total payment amount
  const _calTotalCheckAmount = () => {
    return incomeObj?.length
      ? incomeObj.reduce((acc, each) => {
        acc = (+acc || 0) + (+each?.paymentAmount || 0);
        return acc;
      }, 0)
      : null;
  };

  // handle fields change like checkdata,referenceNumber for income
  const _onFieldChange = (fieldName, value) => {
    switch (fieldName) {
      case "referenceNumber":
        setReferenceNumber(value);
        break;
      case "checkDate":
        setCheckDate(value);
        break;
      default:
        break;
    }
  };

  // handle fields change of amount that is filled in action column
  const _onChangeFormField = (row, value) => {
    const updatedProjects = incomeObj.map((item) =>
      item.id === row.id ? { ...item, paymentAmount: value } : item
    );

    const regex = /^$|^\d+(\.\d{0,2})?$/g;

    if (!regex.test(value)) {
      showToast("Invalid Input", "error");
      return;
    }

    if (value <= row.duePayment) {
      const replaceArray = incomeList.map(
        (i) => updatedProjects.find((o) => o.id === i.id) || i
      );

      // Update state
      setIncomeObj(updatedProjects);
      setIncomeList(replaceArray);
    } else {
      showToast("Entered Value is greater than Due Payment", "error");
    }
  };

  // api call to record payment for each rows i.e income
  const _updateIncomeForRecordPayment = () => {
    const commanArray = incomeObj.filter((item) =>
      selectedRows.includes(item.id)
    );

    const noPaymentAmount = commanArray.some(
      (item) => item.paymentAmount === undefined
    );

    if (noPaymentAmount) {
      showToast("Payment Amount Should Not be Empty", "error");
    } else {
      _manageLoading("markPaidLoading", true);
      let incomeList = incomeObj.map((item) => ({
        id: item.id,
        paymentAmount: item?.paymentAmount || "0",
      }));

      const payload = {
        incomeList,
        checkDate,
        referenceNumber,
      };

      const totalCheckAmount = _calTotalCheckAmount();

      if (totalCheckAmount) payload["totalCheckAmount"] = totalCheckAmount;

      const updateIncomeAPI =
        userData?.type === "agent"
          ? updateAgentIncomeForRecordPayment
          : updateCompanyIncomeForRecordPayment;

      updateIncomeAPI(payload)
        .then((res) => {
          setCheckDate("");
          setReferenceNumber("");
          setSelectedRows([]);
          setIncomeObj([]);
          setLoading({ ...loading, markPaidLoading: false });
          showToast("Recorded Successfully", "success");
          if (userData?.type === "agent") {
            _agentFetchAllIncome();
          } else {
            _companyFetchAllIncome();
          }
        })
        .catch((error) => {
          setCheckDate("");
          setReferenceNumber("");
          setSelectedRows([]);
          setIncomeObj([]);
          setLoading({ ...loading, markPaidLoading: false });
          _setDateRangeValue();
          showToast("Enter valid amount", "error");
        });
      _manageLoading("markPaidLoading", false);
    }
  };

  const _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);
      setFilterDropdownValues((prevValues) => ({
        ...prevValues,
        clientTeamsList: res?.teams || [],
      }));
    } catch (error) {
      errorHandler(error);
    }
  };

  const _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage?.income) {
      try {
        const storedData = JSON.parse(localStorage?.income);

        let start =
          storedData.dateRangeValue !== null &&
          checkIsDateValid(storedData.dateRangeValue[0]);
        let end =
          storedData.dateRangeValue !== null &&
          checkIsDateValid(storedData.dateRangeValue[1]);
        let dateRange;
        if (start && end) {
          dateRange = [
            new Date(convertIsoToUtc(storedData.dateRangeValue[0])),
            new Date(convertIsoToUtc(storedData.dateRangeValue[1])),
          ];
          setDateRangeValue(dateRange);
        }
        setFilters(storedData.filters);
        setTableConfig(storedData.tableConfig);

        if (userData?.type === "signingcompany") {
          _companyFetchAllIncome({
            ...storedData.filters,
            dateRangeValue: dateRange,
            limit: storedData?.tableConfig?.limit,
            pageNumber: storedData?.tableConfig?.pageNumber,
            skip: storedData?.tableConfig?.skip,
          });
          if (filters?.client) {
            _getCompanyListTeamsOfClient(filters?.client);
          }
        } else {
          _agentFetchAllIncome({
            ...storedData.filters,
            dateRangeValue: dateRange,
            limit: storedData?.tableConfig?.limit,
            pageNumber: storedData?.tableConfig?.pageNumber,

            skip: storedData?.tableConfig?.skip,
          });
        }
      } catch (e) {
        if (userData?.type === "signingcompany") {
          _companyFetchAllIncome();
        } else {
          _agentFetchAllIncome();
        }
      }
    } else {
      if (userData?.type === "signingcompany") {
        _companyFetchAllIncome();
      }
    }
  };

  // remove income api call
  const _removeIncome = async (id) => {
    try {
      _manageLoading("deleteIncomeLoading", true);
      await deleteCompanyIncomeById(id);
      _companyFetchAllIncome();
      _manageLoading("deleteIncomeLoading", false);
    } catch (err) {
      errorHandler(err);
      _manageLoading("deleteIncomeLoading", false);
    }
  };

  // delete income if confirmed from popup
  const _removeIncomeAlert = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove this income.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _removeIncome(id);
      }
    });
  };

  // format data for card header in mobile view
  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              <div className="userContent d-flex">
                {/* show the below span only when there are more than 1 signers  */}
                {row?._borrower?.length > 0
                  ? capitalize(row?._borrower[0]?.name?.full)
                  : "N/A"}{" "}
                {row?._borrower?.length > 1 ? (
                  <Suspense fallback={<></>}>
                    <SignerPopover
                      data={row?._borrower}
                      targetId={row?._id}
                      displayType="onlySignerNames"
                    />
                  </Suspense>
                ) : null}
              </div>

              <>
                {userData?.type === "signingcompany" && (
                  <>
                    <Button
                      color="link"
                      onClick={() => _removeIncomeAlert(row?._id)}
                      disabled={
                        row?._closing?.id || loading?.deleteIncomeLoading
                      }
                    >
                      <img
                        src={
                          require("../../../assets/img/deleteIcon.svg").default
                        }
                        alt="delete"
                      />
                    </Button>
                  </>
                )}

                {!row?._closing && (
                  <Button
                    color="link"
                    className="actionBtn"
                    onClick={() =>
                      userData?.type === "signingcompany"
                        ? _onToggleAddIncomeModalCompany(true, row)
                        : _onToggleAddIncomeModalAgent(true, row)
                    }
                  >
                    <img
                      src={require("../../../assets/img/pencil.svg").default}
                      alt="edit"
                    />
                  </Button>
                )}
              </>
            </div>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  // format data for card in mobile view
  const _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <AgentCompanyIncomeCardDataFormat
          row={row}
          userData={userData}
          _onToggleAddNotesModal={_onToggleAddNotesModal}
          agentClosingStatus={agentClosingStatus}
          incomeObj={incomeObj}
          selectedRows={selectedRows}
          _onChangeFormField={_onChangeFormField}
        />
      </Suspense>
    );
  };

  // info card data is being rendered from here
  const _infoCardData = () => {
    const infoArray = [
      {
        name: "Total Orders",
        value: incomeTotalCount,
        image: require("../../../assets/img/TotalOrders.svg").default,
        icon: "TotalOrders",
      },
      {
        name: "Total Income",
        value: formatCurrencyValue(totalAmount),
        image: require("../../../assets/img/TotalIncome.svg").default,
        icon: "TotalIncome",
      },
      {
        name: "Paid",
        value: formatCurrencyValue(totalPaidAmount),
        image: require("../../../assets/img/invoicePaid.svg").default,
        icon: "invoicePaid",
      },
      {
        name: "Pending",
        value: formatCurrencyValue(totalPendingDueAmount),
        h4classname: "text-danger",
        image: require("../../../assets/img/invoicepending.svg").default,
        icon: "statusPending",
      },
    ];
    return infoArray;
  };

  const _redirectToClosingDetails = () => {
    history.push(`/${userData.type}/subscription`);
  };

  useEffect(() => {
    if (userData.type === "signingcompany") {
      // for company
      _getAllListClients(); // get all client list for company
    } else {
      // for agent
      _setDateRangeValue();
      _agentGetAllUnregisteredClient(); // get unregistered clients for agent
    }
    _setFilterDataFromLocalStorage(); // function to set data from local storage to state

    const resetFilterSubscription = HeaderEventEmitter.subscribe(
      "reset-filter",
      () => {
        _resetFilter(); // reset filters
        refreshFunc("accounting-income");
      }
    );

    const addIncomeSubscription = HeaderEventEmitter.subscribe(
      "add-income",
      () => {
        if (userData.type === "signingcompany") {
          _onToggleAddIncomeModalCompany(true); // open add income modal company
        } else {
          _onToggleAddIncomeModalAgent(true);
        }
      }
    );

    // Cleanup subscriptions on unmount
    return () => {
      resetFilterSubscription?.unsubscribe();
      addIncomeSubscription?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _persistFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dateRangeValue, tableConfig]);

  return (
    <>
      <div className="content">

        <div className="responsiveTitle">
          <h2>Income List</h2>
          <div className="rightSide d-flex">
            <Button
              className="floatingButton"
              color="primary"
              onClick={() =>
                userData.type === "signingcompany"
                  ? _onToggleAddIncomeModalCompany(true)
                  : isRegularUser()
                    ? _onToggleAddIncomeModalAgent(true)
                    : _toggleUpgradeAccountModal(true)
              }
            >
              + Add Income
            </Button>

            <Button
              id="company-accounting-income-responsive"
              color="link"
              onClick={_resetFilter}
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
                height={14}
              /> */}
              <SvgIcons type="refresh" />
            </Button>
            <Button
              className="filterBarIcon"
              color="link"
              onClick={filterShowMobile}
            >
              <SvgIcons type="filterIcon" />
            </Button>
            {userData?.type === "agent" && isForMobile ? (
              <UncontrolledDropdown className="bulkAction">
                <DropdownToggle caret color="primary" size="sm">
                  Bulk Action
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() =>
                      props.history.push(`/agent/bulk-upload?paidIncome`)
                    }
                  >
                    Bulk Upload Paid
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      props.history.push(`/agent/bulk-upload?pendingIncome`)
                    }
                  >
                    {" "}
                    Bulk Upload Pending
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null}
          </div>
        </div>

        {/* search filed for responsive will show below 767px */}
        <div className="mobileSearchFiled">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img
                  src={require("../../../assets/img/searchIcon.svg").default}
                  alt="searchIcon"
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              // placeholder="File Number"
              placeholder="Search Signer, Product type & File"
              value={filters.search}
              onChange={(e) => _filterOnChange("search", e.target.value, true)}
            />
          </InputGroup>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={filterShowMobile}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>

        <Suspense fallback={<></>}>
          <AgentCompanyIncomeFilter
            isClassAdded={isClassAdded}
            filterShowMobile={filterShowMobile}
            userData={userData}
            filters={filters}
            _filterOnChange={_filterOnChange}
            _onChangeDatePicker={_onChangeDatePicker}
            dateRangeValue={dateRangeValue}
            _setDateRangeValue={_setDateRangeValue}
            _getAllListClients={_getAllListClients}
            _agentGetAllUnregisteredClient={_agentGetAllUnregisteredClient}
            clientOption={clientOption}
            unregisterClientListOption={unregisterClientListOption}
            filterDropdownValues={filterDropdownValues}
          />
        </Suspense>

        {userData.type === "signingcompany" ? null : isRegularUser() ? (
          <InfoCard
            infoCardArray={_infoCardData()}
            loading={loading.showTableLoading}
            page="income"
          />
        ) : (
          <div
            className="upgradeText"
            onClick={() => _redirectToClosingDetails()}
          >
            Upgrade to unlock.
          </div>
        )}

        {isForMobile ? (
          <Suspense fallback={<></>}>
            <div className="hideDesktop">
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={incomeList}
                headerKeys={incomeCardHeaderKeys}
                dataFormat={_dataFormat}
                totalCount={incomeTotalCount}
                pageSize={tableConfig?.limit}
                onPaginate={(pageNumber, pageSize) =>
                  // _paginate(pageNumber, pageSize)
                  _paginate({
                    pageNumber: pageNumber,
                    pageSize,
                    ...filters,
                    dateRangeValue:
                      dateRangeValue !== null
                        ? getDateRangeValue(dateRangeValue)
                        : null,
                  })
                }
                rowSelection={true}
                selectedRows={selectedRows}
                setSelectedRows={(selectedRows) =>
                  _setSelectedRows(selectedRows)
                }
                showTableLoading={loading.showTableLoading}
                cardHeaderFormat={_cardHeaderFormat}
                cardDataFormat={_cardDataFormat}
              />
            </div>
          </Suspense>
        ) : (
          <Suspense fallback={<></>}>
            <div className="hideMobile">
              {incomeHeaderKeys && incomeHeaderKeys.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={incomeList}
                  headerKeys={incomeHeaderKeys}
                  rowSelection={true}
                  dataFormat={_dataFormat}
                  totalCount={incomeTotalCount}
                  pageSize={tableConfig?.limit}
                  onPaginate={(pageNumber, pageSize) =>
                    // _paginate(pageNumber, pageSize)
                    _paginate({
                      pageNumber: pageNumber,
                      pageSize: pageSize,
                      ...filters,
                      dateRangeValue:
                        dateRangeValue !== null ? dateRangeValue : null,
                    })
                  }
                  onSortChange={(sortName, sortOrder) =>
                    // sort function is disabled
                    _paginate()
                  }
                  selectedRows={selectedRows}
                  setSelectedRows={(selectedRows) =>
                    _setSelectedRows(selectedRows)
                  }
                  showTableLoading={loading.showTableLoading}
                  sizePerPage={tableConfig.limit}
                />
              )}
            </div>
          </Suspense>
        )}
        <Suspense fallback={<></>}>
          <AgentCompanyFooterSection
            checkDate={checkDate}
            isOpenReactDatetime={isOpenReactDatetime}
            loading={loading}
            referenceNumber={referenceNumber}
            _onFieldChange={_onFieldChange}
            calTotalCheckAmount={_calTotalCheckAmount()}
            _updateIncomeForRecordPayment={_updateIncomeForRecordPayment}
            setIsOpenReactDatetime={setIsOpenReactDatetime}
          />
        </Suspense>

        {/* add note modal */}
        <AddNotesModal
          isOpen={addNotesModal.isOpen}
          data={addNotesModal.data}
          toggle={() => _onToggleAddNotesModal()}
          resetDetails={() => _paginate()}
          onSaveNotes={(id, notes) => _onSaveNotes(id, notes)}
        />
        {/* add income modal company */}
        <AddIncomeModalCompany
          isOpen={addIncomeModalCompany.isOpen}
          data={addIncomeModalCompany.data}
          clients={filterDropdownValues.clients}
          toggle={_onToggleAddIncomeModalCompany}
          resetDetails={() => _paginate()}
        />

        <AddIncomeModalAgent
          isOpen={addIncomeModalAgent.isOpen}
          data={addIncomeModalAgent.data}
          signingCompany={filterDropdownValues.signingCompany}
          unregisterClientList={filterDropdownValues.unregisterClientList}
          toggle={_onToggleAddIncomeModalAgent}
          resetDetails={() => _paginate()}
        />
        {/*  download invoice modal */}
        <DownloadInvoiceModal
          isOpen={downloadInvoiceModal?.isOpen}
          data={downloadInvoiceModal?.data}
          toggle={_onToggleDownloadInvoiceModal}
          type="agentIncome"
        // showExtraInfo={false}
        />

        <UpgradeAccountModal
          isOpen={upgradeAccountModal.isOpen}
          toggle={_toggleUpgradeAccountModal}
        />
      </div>
    </>
  );
};

export default memo(AgentCompanyIncomeListAccounting);
