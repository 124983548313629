import React, { Fragment } from "react";
import { Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import useScreenWidth from "../../HelpModule/useScreenWidth";

const SkeletonCard = ({
  count = 1,
  width,
  height = 160,
  borderRadius = 8,
  page = "",
}) => {
  const { isForMobile } = useScreenWidth();
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <Fragment key={`SkeletonCard_${index}`}>
          {page?.length && isForMobile ?
          (
            <div class="d-flex">
              <div class="d-inline-block ml-4 mb-2">
                <Skeleton
                  {...(height ? { height } : {})}
                  {...(width ? { width } : {})}
                  {...(borderRadius ? { borderRadius } : {})}
                />
              </div>
            </div>
          ) :(
            <Col md={4}>
              <div className="mx-2">
                <Skeleton
                  {...(height ? { height } : {})}
                  {...(width ? { width } : {})}
                  {...(borderRadius ? { borderRadius } : {})}
                />
              </div>
            </Col>
          ) }
        </Fragment>
      ))}
    </>
  );
};

export default SkeletonCard;
