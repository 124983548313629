import React, { Suspense, lazy, useEffect, useState } from "react";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  convertIsoToUtc,
  errorHandler,
  formatCurrencyValue,
  formatOnlyDateMoment,
  getDropdownColor,
  getLoggedInUserId,
  isRegularUser,
  openUrlOnNewTab,
  refreshFunc,
  showToast,
  structureQueryParams,
} from "../../../helper-methods";
import { BASE_URL, agentClosingStatus } from "../../../config";
import {
  agentFetchAllStatusReport,
  signingCompanyFetchAllStatusReport,
} from "../../../http/http-calls";
import {
  Button,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Card,
} from "reactstrap";

import { useSelector } from "react-redux";
import CustomPagination from "../components/CustomPagination";
import useScreenWidth from "../components/HelpModule/useScreenWidth";
import { useHistory } from "react-router-dom";
import SkeletonLoading from "../components/Skeletons/SkeletonLoading";
import SvgIcons from "../components/SvgIcons";
const DEFAULT_KEY_FOR_COMPANY = "signingcompany";

const CustomCard = lazy(() => import("../components/CustomCard"));
const AgentCompantStatusReportFilterComponent = lazy(() =>
  import("../components/AgentCompantStatusReportFilterComponent")
);
const TableFormatDataForStatusReport = lazy(() =>
  import("../components/TableFormatDataForStatusReport")
);
const CardDataForStatusReport = lazy(() =>
  import("../components/CardDataForStatusReport")
);

const AgentCompanyStatusReport = () => {
  const userData = useSelector((state) => state.userData);
  const { isForMobile } = useScreenWidth();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Pending");
  const [pageNumber, setPageNumber] = useState(1);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [filters, setFilters] = useState({
    states: "",
    status: "",
  });
  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [closings, setClosings] = useState([]);
  const [closingsBackup, setClosingsBackup] = useState([]);

  const filterShowMobile = () => {
    setIsClassAdded((prevState) => !prevState);
  };

  const _persistFilter = () => {
    if (
      (filters &&
        (filters.states || (filters.status && filters.status !== ""))) ||
      dateRangeValue !== null ||
      pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, pageNumber };
      if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
        localStorage.companystatusreport = JSON.stringify(data);
      } else {
        localStorage.agentstatusreport = JSON.stringify(data);
      }
    } else {
      if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
        delete localStorage.companystatusreport;
      } else {
        delete localStorage.agentstatusreport;
      }
    }
  };

  const _onChangeDatePicker = (newDateRangeValue) => {
    if (newDateRangeValue === null) {
      const filterPayload = {};

      if (filters.states && filters.states.length)
        filterPayload["states"] = filters.states;

      if (filters.status && filters.status.trim().length)
        filterPayload["status"] =
          filters.status !== "" ? filters.status : undefined;
      _getAllStatusReport({ filters: filterPayload });
    }
    setDateRangeValue(newDateRangeValue);

    setLoading(true);
    setPageNumber(1);
    _persistFilter();
  };
  const _filterOnChange = (type, value) => {
    setLoading(true);

    const updatedFilters = { ...filters };
    updatedFilters[type] = value;

    setFilters(updatedFilters);
    _persistFilter();

    if (!value?.length) {
      if (dateRangeValue) {
        _getAllStatusReport({ filters: dateRangeValue });
      } else {
        _getAllStatusReport({ filters: {} });
      }
    }
  };

  const _paginateArray = (
    currentPage,
    itemsPerPage = 10,
    newclosingsBackup,
    tab
  ) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (newclosingsBackup && tab === undefined) {
      let newArray = newclosingsBackup
        .filter((item) => item.status === activeTab)
        .slice(startIndex, endIndex);
      setClosings(newArray);
      setPageNumber(currentPage);
    } else if (tab) {
      let newArray = newclosingsBackup
        .filter((item) => item.status === tab)
        .slice(startIndex, endIndex);
      setClosings(newArray);
      setPageNumber(currentPage);
    } else {
      let newArray = closingsBackup
        .filter((item) => item.status === activeTab)
        .slice(startIndex, endIndex);
      setClosings(newArray);
      setPageNumber(currentPage);
    }
    _persistFilter();
  };

  //For handle tab changes
  const _toggleTab = async (tab) => {
    if (tab === activeTab) {
      return;
    }
    if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
      try {
        setActiveTab(tab);
        setPageNumber(1);

        _paginateArray(1, 10, closingsBackup, tab);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
        setActiveTab("Pending");
        setPageNumber(1);
      }
    } else {
      try {
        setActiveTab(tab);
        setPageNumber(1);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
        setActiveTab("Pending");
        setPageNumber(1);
      }
    }
  };

  const _getAllStatusReport = async (newPayload) => {
    setLoading(true);
    const filterPayload = {};

    if (filters.states && filters.states.length)
      filterPayload["states"] = filters.states;

    if (filters.status && filters.status.trim().length)
      filterPayload["status"] =
        filters.status !== "" ? filters.status : undefined;

    if (dateRangeValue) {
      filterPayload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      filterPayload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    let payload = {
      filters: filterPayload,
    };

    if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
      try {
        let res = await signingCompanyFetchAllStatusReport(
          newPayload ? newPayload : payload
        );
        setClosings(res?.closings?.slice(0, 10) || []);
        setClosingsBackup(res?.closings);
        setLoading(false);
        if (res?.closings.length) {
          _paginateArray(pageNumber, 10, res?.closings);
        }
      } catch (error) {
        setLoading(false);
        errorHandler(error);
      }
    } else {
      try {
        const res = await agentFetchAllStatusReport(
          newPayload ? newPayload : payload
        );
        setClosings(res?.closings);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching status report:", error);
        setLoading(false);
      }
    }
  };

  //For handle local storage company and agent
  const _setFilterDataFromLocalStorage = () => {
    if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
      if (localStorage && localStorage.companystatusreport) {
        try {
          const localStorageData = JSON.parse(localStorage.companystatusreport);
          const {
            filters: localStorageFilters,
            dateRangeValue: localStorageDateRangeValue,
            pageNumber: localStoragePageNumber,
          } = localStorageData;

          let dateRange = null;
          if (localStorageDateRangeValue) {
            dateRange = [
              new Date(convertIsoToUtc(localStorageDateRangeValue[0])),
              new Date(convertIsoToUtc(localStorageDateRangeValue[1])),
            ];
          }

          setFilters(localStorageFilters);
          setDateRangeValue(dateRange);
          setPageNumber(localStoragePageNumber);
          _getAllStatusReport();
        } catch (error) {
          console.error("Error while parsing local storage data:", error);
          _getAllStatusReport();
        }
      } else {
        _getAllStatusReport();
      }
    } else {
      if (localStorage && localStorage.agentstatusreport) {
        try {
          const savedFilters = JSON.parse(localStorage.agentstatusreport);
          let dateRange = null;
          if (savedFilters.dateRangeValue) {
            dateRange = [
              new Date(convertIsoToUtc(savedFilters.dateRangeValue[0])),
              new Date(convertIsoToUtc(savedFilters.dateRangeValue[1])),
            ];
          }
          setFilters(savedFilters.filters);
          setDateRangeValue(dateRange);
          setPageNumber(savedFilters.pageNumber);
          _getAllStatusReport();
        } catch (error) {
          console.error("Error parsing saved filters:", error);
          _getAllStatusReport();
        }
      } else {
        console.log("No saved filters found.");
        _getAllStatusReport();
      }
    }
  };

  //Reset filter for company and agent
  const _resetFilter = () => {
    setFilters({ states: "", status: "" });
    setDateRangeValue(null);
    _getAllStatusReport();
    if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
      refreshFunc("company-reporting-status-report-responsive");
    } else {
      refreshFunc("agent-reporting-status-report-responsive");
    }
  };

  // This function use for download data company and agent
  const _downloadData = (action = "download") => {
    if (!Object.keys(closings).length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (filters.states && filters.states.length)
      payload["states"] = filters.states;

    if (filters.status && filters.status.trim().length)
      payload["status"] = filters.status.trim();

    if (dateRangeValue) {
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(
        `${BASE_URL}/${userData?.type}/download/status-report${queryParams}`
      );
  };

  //agent fee calculate for company and agent
  const _calculateAgentTotalFee = (array) => {
    if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
      return array?.reduce((totalFee, currentItem) => {
        if (
          currentItem.status === activeTab &&
          currentItem.agentFee !== undefined
        ) {
          return totalFee + currentItem.agentFee;
        } else {
          return totalFee;
        }
      }, 0);
    } else {
      let arr = array.map((item) =>
        item.agentFee !== undefined ? item.agentFee : 0
      );

      return arr.reduce((a, b) => a + b, 0);
    }
  };

  //For manage data mobile view
  const _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <CardDataForStatusReport userData={userData} row={row} />
      </Suspense>
    );
  };

  //For manage Data Table view
  const _tableFormatWithArray = (data) => {
    return (
      <Suspense fallback={<></>}>
        <TableFormatDataForStatusReport
          userData={userData}
          data={data}
          loading={loading}
        />
      </Suspense>
    );
  };

  const _getStatusIcon = (activeTab) => {
    const currentStatusConfig = agentClosingStatus?.find((each) => each.value === activeTab)
    console.log("currentStatusConfig >>", currentStatusConfig);
    return currentStatusConfig?.icon
  }


  useEffect(() => {
    setLoading(true);
    if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
      _setFilterDataFromLocalStorage();

      const resetFilterSubscription = HeaderEventEmitter?.subscribe(
        "reset-filter",
        () => {
          console.log("filter reset hit");
          _resetFilter();
          refreshFunc("company-reporting-status-report");
        }
      );

      const downloadStatusReportSubscription = HeaderEventEmitter?.subscribe(
        "download-status-report",
        () => {
          console.log("status-report");
          _downloadData();
        }
      );

      return () => {
        resetFilterSubscription?.unsubscribe();
        downloadStatusReportSubscription?.unsubscribe();
      };
    } else {
      if (isRegularUser()) {
        _setFilterDataFromLocalStorage();
        HeaderEventEmitter?.subscribe("reset-filter", () => {
          console.log("filter reset hit");
          _resetFilter();
          refreshFunc("agent-reporting-status-report");
        });
      } else {
        history.goBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      Object.values(filters).some((value) => value !== "") ||
      dateRangeValue !== null
    ) {
      _getAllStatusReport(); // This is for fetch all data in company and agent side
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dateRangeValue]);

  return (
    <div className="content">
      <div className="responsiveTitle">
        <h2>Status Report</h2>
        <div className="rightSide">
          <Button id="company-reporting-status-report-responsive" color="link" onClick={_resetFilter}>
            <SvgIcons type={"refresh"} />
          </Button>

          <Button
            className="filterBarIcon"
            color="link"
            onClick={filterShowMobile}
          >

            <SvgIcons type={"filterIcon"} />
          </Button>
        </div>
      </div>
      <div
        onClick={filterShowMobile}
        className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
      ></div>
      <div
        className={`filterContainer  responsiveFilter ${isClassAdded ? "show" : ""
          }`}
      >
        <div className="filterIcon">
          <SvgIcons type={"filterIcon"} />
          Filter by
        </div>
        <div className="mobileTitle">
          <h2>Filter by</h2>
          <Button
            color="link"
            className="closeButton"
            onClick={filterShowMobile}
          >
            <img
              src={require("../../../assets/img/close_grey.svg").default}
              alt="close"
              height={12}
            />
          </Button>
        </div>
        <Suspense fallback={<></>}>
          <AgentCompantStatusReportFilterComponent
            _onChangeDatePicker={_onChangeDatePicker}
            dateRangeValue={dateRangeValue}
            filters={filters}
            _filterOnChange={_filterOnChange}
            _downloadData={_downloadData}
          />
        </Suspense>
        <div className="clearButton">
          <Button size="md" color="primary" onClick={filterShowMobile}>
            Close
          </Button>
        </div>
      </div>

      <div className="downloadButtonPos">
        <Button color="link" outline onClick={() => _downloadData("download")}>
          Download
          <SvgIcons type={'download'} />
          {/* <img
            src={require(`../../../assets/img/download_blue.png`)}
            alt="download"
          /> */}
        </Button>
      </div>
      <Row>
        <Col md="12">
          <div className="verticalTabs">
            <Nav tabs>
              {agentClosingStatus?.map((each) => (

                <NavItem NavItem key={`NavItem_${each.value}`}>
                  <NavLink
                    className={activeTab === each.value ? "active" : ""}
                    onClick={() => {
                      _toggleTab(each.value);
                    }}
                  >
                    {/* <img
                      src={require(`../../../assets/img/${activeTab === each.value
                        ? each.imageBlueName
                        : each.imageBlackName
                        }.png`)}
                      alt={each.label}
                    /> */}
                    <SvgIcons type={`${each.icon}`} />
                    {each.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>

            <TabContent>
              <TabPane>
                <Row>
                  <Col xs={6}>
                    {loading ? (
                      <SkeletonLoading
                        type="card"
                        count={1}
                        height={isForMobile ? 140 : 100}
                        width={isForMobile ? 150 : 600}
                      />
                    ) : (
                      <Card className="infoCard">
                        <CardBody>
                          <div className="infoContent">
                            <span>{activeTab}</span>

                            {console.log(activeTab)}

                            {userData?.type === DEFAULT_KEY_FOR_COMPANY ? (
                              <h4 className={`${getDropdownColor(activeTab)}`}>
                                {closingsBackup?.length
                                  ? // closings.length
                                  closingsBackup.reduce(
                                    (accumulator, currentObject) => {
                                      if (
                                        currentObject.status === activeTab
                                      ) {
                                        return accumulator + 1;
                                      } else {
                                        return accumulator;
                                      }
                                    },
                                    0
                                  )
                                  : "0"}
                              </h4>
                            ) : (
                              <h4 className={`${getDropdownColor(activeTab)}`}>
                                {closings?.[activeTab]?.length
                                  ? closings[activeTab].length
                                  : "0"}
                              </h4>
                            )}
                          </div>

                          <div className="icon">
                            {/* <img
                              src={
                                require("../../../assets/img/invoicepending.svg")
                                  .default
                              }
                              alt="notaryActs"
                            /> */}
                            <SvgIcons type={_getStatusIcon(activeTab)} />
                          </div>

                        </CardBody>
                      </Card>
                    )}
                  </Col>
                  <Col xs={6}>
                    {loading ? (
                      <SkeletonLoading
                        type="card"
                        count={1}
                        height={isForMobile ? 140 : 100}
                        width={isForMobile ? 150 : 600}
                      />
                    ) : (
                      <Card className="infoCard">
                        <CardBody>
                          <div className="infoContent">
                            <span>Agent Fee</span>
                            {userData?.type === DEFAULT_KEY_FOR_COMPANY ? (
                              <h4>
                                {closings?.length
                                  ? formatCurrencyValue(
                                    _calculateAgentTotalFee(closingsBackup)
                                  )
                                  : formatCurrencyValue(0)}
                              </h4>
                            ) : (
                              <h4>
                                {closings?.[activeTab]?.length
                                  ? formatCurrencyValue(
                                    _calculateAgentTotalFee(
                                      closings[activeTab]
                                    )
                                  )
                                  : formatCurrencyValue(0)}
                              </h4>
                            )}
                          </div>
                          <div className="icon">
                            <SvgIcons type={"invoicePaid"} />
                          </div>
                        </CardBody>
                      </Card>
                    )}
                  </Col>

                  {userData?.type === DEFAULT_KEY_FOR_COMPANY ? (
                    <Col md={12} className="mt-3">
                      <>
                        <Suspense fallback={<></>}>
                          {isForMobile ? (
                            <div className="hideDesktop">
                              <CustomCard
                                isPageStartFromOne={true}
                                pageNumber={pageNumber}
                                tableData={closings}
                                isPagination={false}
                                columnSizeMD={12}
                                totalCount={
                                  closingsBackup?.filter(
                                    (item) => item?.status === activeTab
                                  )?.length
                                }
                                rowSelection={false}
                                showTableLoading={loading}
                                cardDataFormat={_cardDataFormat}
                              />
                            </div>
                          ) : (
                            <div className="hideMobile">
                              {_tableFormatWithArray(closings)}
                            </div>
                          )}
                          <CustomPagination
                            data={closings}
                            dataCount={
                              closingsBackup?.filter(
                                (item) => item?.status === activeTab
                              )?.length
                            }
                            isPageStartFromOne={true}
                            pageNumber={pageNumber}
                            pageSize={10}
                            onPageChange={(pageNumber) =>
                              _paginateArray(pageNumber)
                            }
                            isHideForLessData={true}
                          />
                        </Suspense>
                      </>
                    </Col>
                  ) : (
                    <Col md={12} className="mt-3">
                      <>
                        <Suspense fallback={<></>}>
                          {!isForMobile ? (
                            <div className="hideMobile">
                              {_tableFormatWithArray(
                                closings[activeTab]?.slice(
                                  (pageNumber - 1) * 5,
                                  pageNumber * 5
                                )
                              )}
                            </div>
                          ) : (
                            <div className="hideDesktop">
                              <CustomCard
                                isPageStartFromOne={true}
                                pageNumber={pageNumber}
                                tableData={closings[activeTab]?.slice(
                                  pageNumber * 5 - 5,
                                  pageNumber * 5
                                )}
                                isPagination={false}
                                totalCount={closings[activeTab]?.length}
                                rowSelection={false}
                                showTableLoading={loading}
                                cardDataFormat={_cardDataFormat}
                              />
                            </div>
                          )}
                          <CustomPagination
                            data={closings[activeTab]?.slice(
                              pageNumber,
                              pageNumber + 5
                            )}
                            dataCount={closings[activeTab]?.length}
                            pageNumber={pageNumber}
                            pageSize={5}
                            onPageChange={(pageNumber) => {
                              setPageNumber(pageNumber);
                              _persistFilter();
                            }}
                            isPageStartFromOne={true}
                            isHideForLessData={true}
                          />
                        </Suspense>
                      </>
                    </Col>
                  )}
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </div >
  );
};

export default AgentCompanyStatusReport;
