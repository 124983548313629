import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Button,
  Progress,
  FormGroup,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  deepClone,
  errorHandler,
  formatDate,
  showToast,
} from "../../../helper-methods";
import moment from "moment";
import {
  handleAuthClick,
  initializeClient,
} from "../../../helper-methods/googleCalendarService";
import { userGoogleCalendarSync } from "../../../http/http-calls";
import { store as REDUX_STORE } from "../../../redux/store";

class GoogleCalendarSyncModal extends Component {
  state = {
    formData: {
      from: {
        value: "",
        error: null,
        isDirty: false,
      },
      to: {
        value: "",
        error: null,
        isDirty: false,
      },
    },
    loading: false,
  };

  _resetStateModal = () => {
    this.setState({
      formData: {
        from: {
          value: "",
          error: null,
          isDirty: false,
        },
        to: {
          value: "",
          error: null,
          isDirty: false,
        },
      },
      loading: false,
    });
  };

  _setFormData = () => {
    const { formData } = deepClone(this.state);
    // formData["from"].value = moment().subtract(2, "week").toISOString();
    // formData["to"].value = moment().add(2, "week").toISOString();

    formData["from"].value = moment().toISOString();
    formData["to"].value = moment().add(1, "year").toISOString();
    this.setState({ formData });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _validateFormData = () => {
    return new Promise((resolve, reject) => {
      const { formData } = this.state;

      let isFormValid = true;

      Object.keys(formData).forEach((key) => {
        if (formData[key].isDirty) {
          switch (key) {
            case "to":
            case "from": {
              if (formData[key].value && formData[key].value.length) {
                if (
                  formData["to"].value &&
                  formData["from"].value &&
                  (formData["from"].value === formData["to"].value ||
                    moment(formData["to"].value).diff(
                      moment(formData["from"].value),
                      "days"
                    ) < 7 ||
                    moment(formData["to"].value).diff(
                      moment(formData["from"].value),
                      "month"
                    ) > 2)
                ) {
                  formData[key].isDirty = true;
                  formData[key].error =
                    "*Invalid Date, date range should be in between one week to three months";
                  isFormValid = false;
                } else {
                  formData[key].isDirty = false;
                  formData[key].error = null;
                }
              } else {
                formData[key].isDirty = true;
                formData[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formData }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormData = (fieldName, value = "") => {
    const { formData } = deepClone(this.state);
    formData[fieldName].value = value ? new Date(value).toISOString() : "";
    formData[fieldName].isDirty = true;
    this.setState({ formData }, () => {
      // this._validateFormData();
    });
  };

  _markAllFormDataFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formData } = this.state;
      Object.keys(formData).forEach((e) => {
        formData[e].isDirty = true;
      });
      this.setState({ formData }, () => resolve(true));
    });
  };

  _userGoogleCalendarSync = async (payload) => {
    try {
      let response = await userGoogleCalendarSync(payload);
      showToast("Google Calendar synced Successfully", "success");
      this.props.resetDetails();
      this._closeModal();
      // console.log(response.isGoogleCalendarSignInDone)
      this.props.updateUserObj({
        isGoogleCalendarSignInDone: response?.isGoogleCalendarSignInDone,
      });
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _handleGoogleCalendarAuthClick = async () => {
    try {
      await this._markAllFormDataFieldDirty();

      // const isFormValid = await this._validateFormData();
      const { userData } = REDUX_STORE.getState();

      let code;
      // if (isFormValid) {
      this.setState({ loading: true });
      if (!userData.user.isGoogleCalendarSignInDone) {
        code = await handleAuthClick();
      }
      const { formData } = this.state;

      const payload = {
        // accessToken: access_token,
        code: !userData.user.isGoogleCalendarSignInDone ? code : undefined,
        // startDate: formData.from.value,
        // endDate: formData.to.value,
        startDate: moment(formData.from.value).utc().format(),
        endDate: moment(formData.to.value).utc().format(),
      };

      this._userGoogleCalendarSync(payload);
      // }
    } catch (error) {
      console.log("error >> ", error);
      errorHandler(error);
      this._closeModal();
    }
  };

  _initializeGoogleCalendar = async () => {
    try {
      this.setState({ loading: true });
      await initializeClient();
      this.setState({ loading: false });
    } catch (error) {
      console.log("error >> ", error);
      errorHandler(error);
      this._closeModal();
    }
  };

  componentDidMount = () => {
    const { isOpen } = this.props;
    if (isOpen) {
      this._initializeGoogleCalendar();

      this._setFormData();
    }
  };

  render() {
    const { formData, loading } = this.state;

    const { isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={() => this._closeModal()} centered>
        <ModalHeader toggle={() => this._closeModal()}>
          Google Calendar Sync
        </ModalHeader>

        <ModalBody>
          <FormGroup
            className={`floatingLabel mt-3 ${
              formData.from.value || this.state.isOpenReactDatetime
                ? "valueAdded"
                : ""
            }`}
          >
            <ReactDatetime
              className="calendarIcon"
              inputProps={{
                className: "form-control",
                value: formatDate(formData.from.value),
                disabled: loading,
              }}
              value={formData.from.value ? new Date(formData.from.value) : ""}
              onChange={(e) => this._onChangeFormData("from", e._d)}
              isValidDate={(current) =>
                formData.to.value
                  ? current.isBefore(
                      moment(formData.to.value).subtract(1, "day")
                    )
                  : true
              }
              onOpen={() => this.setState({ isOpenReactDatetime: true })}
              onClose={() => this.setState({ isOpenReactDatetime: false })}
              dateFormat={true}
              timeFormat={false}
            />
            <Label>From Date</Label>
            {formData.from.error && (
              <div className="validation-error">{formData.from.error}</div>
            )}
          </FormGroup>
          <FormGroup
            className={`floatingLabel ${
              formData.to.value || this.state.isOpenReactDatetime
                ? "valueAdded"
                : ""
            }`}
          >
            <ReactDatetime
              inputProps={{
                className: "form-control",
                value: formatDate(formData.to.value),
                disabled: loading,
              }}
              value={formData.to.value ? new Date(formData.to.value) : ""}
              onChange={(e) => this._onChangeFormData("to", e._d)}
              isValidDate={(current) =>
                formData.from.value
                  ? current.isAfter(new Date(formData.from.value))
                  : true
              }
              dateFormat={true}
              timeFormat={false}
              onOpen={() => this.setState({ isOpenReactDatetime: true })}
              onClose={() => this.setState({ isOpenReactDatetime: false })}
            />
            <Label>To Date</Label>
            {formData.to.error && (
              <div className="validation-error">{formData.to.error}</div>
            )}
          </FormGroup>
          <p className="text-muted fs-12">
            Disclosure: Closewise's use and transfer of information received
            from Google APIs to any other app will adhere to
            <a
              rel="noreferrer"
              target="_blank"
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            >
              {" "}
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements.”"
          </p>
        </ModalBody>

        <ModalFooter>
          {/* <Button
            color="primary"
            size="lg"
            outline
            onClick={() => this._closeModal()}
          >
            Cancel
          </Button> */}

          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={() => this._handleGoogleCalendarAuthClick()}
            style={{ width: "80%" }}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}
            <img
              src={require("../../../assets/img/Google-calandar.png")}
              alt="google calandar"
              height={18}
            />{" "}
            Connect google Calendar
          </Button>
        </ModalFooter>

        {loading && Number(this.props.percentage) > 0 && (
          <Progress animated color="success" value={this.props.percentage} />
        )}
      </Modal>
    );
  }
}

export default GoogleCalendarSyncModal;
