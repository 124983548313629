/* eslint-disable no-unused-vars */
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert, Button, Col, Row } from "reactstrap";
import { COMPANYSUBSCRIPTIONDATA } from "../../../config/subscriptionConfig";
import { errorHandler, formatCurrencyValue } from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  getSettingDetail,
  getSigningCompanySubscription,
} from "../../../http/http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { updateUserObj } from "../../../redux/actions/user-data";

const BuySubscriptionModal = lazy(() =>
  import("../components/buy-subscription-modal")
);
const TitleCompanySubscription = lazy(() =>
  import("../components/titleCompanySubscription")
);

const MySubscription = () => {
  const userData = useSelector((state) => state?.userData);
  const dispatch = useDispatch();
  const history = useHistory();

  const [subscription, setSubscription] = useState(null);
  const [titleSettings, setTitleSettings] = useState(null);
  const [premiumPlan, setPremiumPlan] = useState(null);
  const [billingCycle, setBillingCycle] = useState({
    Basic: false,
    Premium: false,
  });
  const [billingCycleBackup, setBillingCycleBackup] = useState({
    Basic: false,
    Premium: false,
  });

  const [subscriptionPlans, setSubscriptionPlans] = useState({
    freePlan: null,
    basicPlan: null,
    premiumPlan: null,
  });

  const [buySubscriptionModal, setBuySubscriptionModal] = useState({
    isOpen: false,
    data: null,
    subscriptionPlan: null,
  });

  const _onToggleBuySubscriptionModal = (data, type) => {
    const newBuySubscriptionModal = { ...buySubscriptionModal };

    newBuySubscriptionModal.isOpen = !newBuySubscriptionModal.isOpen;
    newBuySubscriptionModal.data = { plan: data, type: type };
    newBuySubscriptionModal.subscriptionPlan = [
      subscriptionPlans?.freePlan,
      subscriptionPlans?.basicPlan,
      subscriptionPlans?.premiumPlan,
    ];

    setBuySubscriptionModal(newBuySubscriptionModal);
  };

  useEffect(() => {
    _companySubscriptionDetail();
    _getSettingDetail();
    HeaderEventEmitter.subscribe("billing", () => {
      userData.user.isCardAdded && _goToBillingPage();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // redirect to billing page
  const _goToBillingPage = () => {
    history.push(`/${userData?.type}/billing`);
  };

  // update userObj in redux and redirect to company profile page
  const _redirectToCompanyProfile = () => {
    let data = {
      isCardAdded: true,
    };

    dispatch(updateUserObj(data));
    history.push("/signingcompany/profile");
  };

  // get setting details from backend
  const _getSettingDetail = async () => {
    try {
      dispatch(showLoader("Fetching Details..."));
      const res = await getSettingDetail();

      let freePlan = res?.setting?.subscriptionPlan?.find(
        (item) => item.plan === "Free"
      );
      let basicPlan = res?.setting?.subscriptionPlan?.find(
        (item) => item.plan === "Basic"
      );
      let premiumPlan = res?.setting?.subscriptionPlan?.find(
        (item) => item.plan === "Premium"
      );

      const updatedSubscriptionPlans = {
        freePlan,
        basicPlan,
        premiumPlan,
      };
      setSubscriptionPlans(updatedSubscriptionPlans);

      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _companySubscriptionDetail = async () => {
    try {
      dispatch(showLoader("Fetching Details..."));

      const res = await getSigningCompanySubscription();

      if (res?.subscription !== null) {
        setSubscription(res?.subscription);
        setBillingCycle((prev) => ({
          ...prev,
          [res.subscription.plan]:
            res.subscription.billingCycle === 365 ? true : false,
        }));

        setBillingCycleBackup((prev) => ({
          ...prev,
          [res.subscription.plan]:
            res.subscription.billingCycle === 365 ? true : false,
        }));
      }

      dispatch(updateUserObj({ _subscription: res?.subscription || [] }));
      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  // function to render plan button dynamically for free, basic and premium type
  const _renderPlanButton = (plan) => {
    switch (plan) {
      case "Free": {
        return (
          <>
            {subscription !== null ? (
              subscription?.isExpired ? (
                <Button
                  color="primary"
                  outline
                  onClick={() => _onToggleBuySubscriptionModal("Free", "renew")}
                >
                  Renew
                </Button>
              ) : subscription?.plan === "Basic" ||
                subscription?.plan === "Premium" ? (
                <Button
                  color="primary"
                  outline
                  onClick={() =>
                    _onToggleBuySubscriptionModal("Free", "downgrade")
                  }
                >
                  Downgrade
                </Button>
              ) : null
            ) : (
              <Button
                color="primary"
                onClick={() => _onToggleBuySubscriptionModal("Free", "buy")}
              >
                Buy
              </Button>
            )}
          </>
        );
      }

      case "Basic": {
        return (
          <>
            {subscription !== null ? (
              subscription?.isExpired ? (
                <Button
                  color="primary"
                  outline
                  onClick={() =>
                    _onToggleBuySubscriptionModal("Basic", "renew")
                  }
                >
                  Renew
                </Button>
              ) : subscription?.plan === "Premium" ? (
                <Button
                  color="primary"
                  outline
                  onClick={() =>
                    _onToggleBuySubscriptionModal("Basic", "downgrade")
                  }
                >
                  Downgrade
                </Button>
              ) : subscription?.plan !== "Basic" ||
                billingCycle.Basic !== billingCycleBackup.Basic ? (
                <Button
                  color="primary"
                  onClick={() =>
                    _onToggleBuySubscriptionModal("Basic", "upgrade")
                  }
                >
                  Upgrade
                </Button>
              ) : null
            ) : (
              <Button
                color="primary"
                onClick={() => _onToggleBuySubscriptionModal("Basic", "buy")}
              >
                Buy
              </Button>
            )}
          </>
        );
      }

      case "Premium": {
        return (
          <>
            {subscription !== null ? (
              subscription?.isExpired ? (
                <Button
                  color="primary"
                  onClick={() =>
                    _onToggleBuySubscriptionModal("Premium", "renew")
                  }
                >
                  Renew
                </Button>
              ) : subscription?.plan !== "Premium" ||
                billingCycle.Premium !== billingCycleBackup.Premium ? (
                <Button
                  color="primary"
                  onClick={() =>
                    _onToggleBuySubscriptionModal("Premium", "upgrade")
                  }
                >
                  Upgrade
                </Button>
              ) : null
            ) : (
              <Button
                color="primary"
                onClick={() => _onToggleBuySubscriptionModal("Premium", "buy")}
              >
                Buy
              </Button>
            )}
          </>
        );
      }

      default:
    }
  };

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <h2>My Subscription</h2>

          <div className="rightSide">
            <Button
              className="floatingButton"
              color="primary"
              onClick={() => {
                history.push(`/${userData?.type}/billing`); // on click redirect to billing page
              }}
            >
              Billing
            </Button>
          </div>
        </div>
        {
          //  userData.user.isTitleCompany
          false ? (
            <TitleCompanySubscription
              subscription={subscription}
              subscriptionsettings={titleSettings}
              onToggleBuySubscriptionModal={this._onToggleBuySubscriptionModal}
            />
          ) : (
            <Row className="justify-content-center justify-content-xl-start">
              {subscription === null ? (
                <Col md="12">
                  <Alert color="danger" className="mb-4">
                    <i className="fa fa-exclamation-triangle" /> Please Select
                    any Plan
                  </Alert>
                </Col>
              ) : null}

              {COMPANYSUBSCRIPTIONDATA?.map((each) => (
                <Col md={6} lg={6} xl={4} className="mb-4 mb-xl-0">
                  <div className="subscriptionPlanCard">
                    <h1 className="subscriptionTitle">
                      <span>{each?.heading}</span>
                      {subscription?.plan === each?.plan ? (
                        <div className="currentPlan">Current Plan</div>
                      ) : null}
                    </h1>
                    <div className="priceWrap">
                      <span className="price">
                        {each?.plan !== "Premium"
                          ? each?.price
                          : formatCurrencyValue(
                              subscriptionPlans?.premiumPlan?.amount
                            )}
                      </span>{" "}
                      <span className="subMonth">{each?.period}</span>
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: 0,
                        marginTop: "20px",
                      }}
                    >
                      {each?.para}
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: 10,
                        marginTop: "2px",
                      }}
                    >
                      {/* {each?.subPara} */}
                    </p>

                    <div className="subscriptionContent text-center">
                      {_renderPlanButton(each?.plan)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )
        }

        <Suspense fallback={<></>}>
          <BuySubscriptionModal
            isOpen={buySubscriptionModal.isOpen}
            data={buySubscriptionModal.data}
            toggle={() => _onToggleBuySubscriptionModal()}
            billingCycle={billingCycle}
            reload={() => {
              _companySubscriptionDetail();
              _getSettingDetail();
            }}
            subscriptionPlan={buySubscriptionModal.subscriptionPlan}
            redirect={() => {
              _redirectToCompanyProfile();
            }}
            isTitleCompany={userData.user.isTitleCompany}
          ></BuySubscriptionModal>
        </Suspense>
      </div>
    </>
  );
};

export default MySubscription;
