import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  deepClone,
  formatDate,
  showToast,
  uploadFileOnServer,
  isRegularUser,
  errorHandler,
  isPastDate,
} from "../../../helper-methods";
import {
  createAgentExpense,
  updateAgentExpense,
} from "../../../http/http-calls";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import {
  // itemListOptionsConfig,
  recurringFrequencyConfig,
} from "../../../config";
import CreatableSelect from "react-select/creatable";

// Add Expense
class AddExpense extends Component {
  state = {
    formFields: {
      date: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      amount: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      type: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      notes: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      receipt: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isValidate: false,
      },
      recurringFrequency: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      isShowRecurringFrequency: {
        value: false,
        error: null,
        isDirty: false,
        isValidate: false,
      },
    },
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    loading: false,
  };
  // reset state
  _resetStateModal = () => {
    const defaultValue = {
      value: "",
      error: null,
      isDirty: false,
      isValidate: true,
    };
    this.setState({
      formFields: {
        date: {
          ...defaultValue,
        },
        amount: {
          ...defaultValue,
        },
        type: {
          ...defaultValue,
        },
        notes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        receipt: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
          },
          error: null,
          isValidate: false,
        },
        recurringFrequency: {
          ...defaultValue,
        },
        isShowRecurringFrequency: {
          value: false,
          error: null,
          isDirty: false,
          isValidate: false,
        },
      },
      loading: false,
    });
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen, data } = this.props;
    if (isOpen && isOpen !== prevProps.isOpen && data) {
      this._setFormFields(data); // set data which are passed from parent as props
    }
  };

  //set state function
  _setFormFields = (data) => {
    const { formFields } = this.state;

    formFields.date.value = data?.date;
    formFields.amount.value = data?.amount;
    formFields.type.value = data?.item;
    formFields.notes.value = data?.notes;
    formFields.isShowRecurringFrequency.value = data?.recurring;
    formFields.recurringFrequency.value = data?.period;

    this.setState({ formFields }, () => console.log(this.state.formFields));
  };

  // close function
  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  // validate
  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            case "type":
            case "date": {
              if (formFields[key].value?.trim().length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "recurringFrequency": {
              if (formFields.isShowRecurringFrequency.value) {
                if (String(formFields[key].value)?.length) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "amount": {
              if (String(formFields[key].value)?.trim().length) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) <= 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Amount must be a positive number";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  // handle on change for field
  _onChangeFormField = (fieldName, value) => {
    const { formFields } = this.state;
    if (fieldName === "amount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    if (fieldName === "date") {
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
      formFields["recurringFrequency"].value = "";
      formFields["isShowRecurringFrequency"].value = false;
    } else formFields[fieldName].value = value;

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  // detete file that is selected
  _deleteFile = () => {
    const { formFields } = deepClone(this.state);
    formFields["receipt"] = {
      file: {
        previewBlob: null,
        uploadData: null,
        type: null,
      },
      error: null,
      isValidate: false,
    };
    this.setState({ formFields });
  };

  // upload file from local system is handled by this function
  _updateFile = async (event) => {
    const { formFields } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      const objFile = event.target.files[0];
      const objFileType = objFile.type.split("/")[0];
      if (objFileType === "image" || objFile.type.includes("pdf")) {
        formFields.receipt.file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType === "application" ? "pdf" : objFileType,
        };

        this.setState({ formFields });
      } else {
        showToast("Only Image or PDF file is allowed", "error");
      }
    }
  };

  // make all field dirty isDirty=true
  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  // update agent expense
  _editAgentExpense = async (id, payload) => {
    try {
      await updateAgentExpense(id, payload);

      showToast("Expense Updated", "success");
      this.props.resetDetails();
      this._closeModal();
    } catch (err) {
      this.setState({ loading: false });
      errorHandler(err);
    }
  };

  // api for create agent expense
  _createAgentExpense = (payload) => {
    createAgentExpense(payload)
      .then((res) => {
        showToast("Expense Created", "success");
        this.props.resetDetails();
        this._closeModal();
      })
      .catch((error) => {
        this.setState({ loading: false });
        errorHandler(error);
      });
  };

  // on submit method
  _onSubmit = async (e) => {
    if (e) e.preventDefault();

    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();
    console.log("isFormValid", isFormValid);

    if (isFormValid) {
      this.setState({ loading: true });

      const { formFields } = this.state;

      const payload = {
        date: formFields.date.value,
        amount: String(formFields?.amount?.value)?.trim(),
        item: formFields.type.value,
        notes: formFields.notes.value ? formFields.notes.value.trim() : "",
      };

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", { payload });

      if (
        formFields.isShowRecurringFrequency.value &&
        formFields.recurringFrequency.value
      )
        payload["period"] = Number(formFields.recurringFrequency.value);

      if (
        formFields.receipt &&
        formFields.receipt.file &&
        formFields.receipt.file.uploadData
      ) {
        const uploadedFilesRes = await uploadFileOnServer([
          formFields.receipt.file,
        ]);

        payload["receipt"] = uploadedFilesRes[0].url;
        payload["docType"] = uploadedFilesRes[0].docType;
        payload["receiptName"] = uploadedFilesRes[0].title;
      }

      if (this.props.data?._id) {
        this._editAgentExpense(this.props.data?._id, payload); // update
      } else {
        this._createAgentExpense(payload); // create
      }
    }
  };

  // upgrade account modal
  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  render() {
    const { formFields, loading, upgradeAccountModal } = this.state;

    const { isOpen, expenseType, data } = this.props;

    console.log(this.state.formFields);
    return (
      <>
        <Modal isOpen={isOpen} toggle={this._closeModal} scrollable centered>
          <ModalHeader toggle={this._closeModal}>
            {data?._id ? "Edit" : "Add"} Expense
          </ModalHeader>
          <ModalBody>
            <FormGroup
              className={`floatingLabel ${formFields.date.value || this.state.isOpenReactDatetime
                  ? "valueAdded"
                  : ""
                }`}
            >
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: " ",
                  value: formatDate(formFields.date.value),
                  disabled: data?._id,
                }}
                onChange={(e) => this._onChangeFormField("date", e._d)}
                closeOnSelect={true}
                isValidDate={(current) => current.isBefore(new Date())}
                timeFormat={false}
                onOpen={() => this.setState({ isOpenReactDatetime: true })}
                onClose={() => this.setState({ isOpenReactDatetime: false })}
              />
              <Label>Date</Label>
              {formFields.date.error && (
                <div className="validation-error">{formFields.date.error}</div>
              )}
            </FormGroup>
            <FormGroup className="floatingLabel withInputGroup">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder=" "
                  value={formFields.amount.value}
                  name="amount"
                  onChange={(event) =>
                    this._onChangeFormField("amount", event.target.value)
                  }
                  disabled={
                    data?.recurring === true || data?.recurring === false
                  }
                />
                <Label>Amount</Label>
              </InputGroup>
              {formFields.amount.error && (
                <div className="validation-error">
                  {formFields.amount.error}
                </div>
              )}
            </FormGroup>
            <FormGroup className="searchDropdown">
              <Label>Item</Label>
              <CreatableSelect
                value={{
                  label: formFields.type.value,
                  value: formFields.type.value,
                }}
                onChange={(event) =>
                  this._onChangeFormField("type", event.value)
                }
                options={expenseType.filter(
                  (item) => item?.value !== "Agent Expense"
                )}
                className="customSelect"
                isDisabled={
                  data?.recurring === true || data?.recurring === false
                }
              />
              {formFields.type.error && (
                <div className="validation-error">{formFields.type.error}</div>
              )}
            </FormGroup>
            {/* {isRegularUser()&& */}
            {formFields.receipt?.file?.previewBlob ? (
              <div className="uploadPreview">
                <img
                  className="uploadedImg"
                  src={
                    formFields.receipt.file.type === "image"
                      ? formFields.receipt.file.previewBlob
                      : require("../../../assets/img/file_icon.png")
                  }
                  alt="upload"
                />
                <span>
                  {formFields.receipt.file.uploadData &&
                    formFields.receipt.file.uploadData.name
                    ? formFields.receipt.file.uploadData.name
                    : null}
                </span>
                <Button
                  color="danger"
                  className="deletePreview"
                  onClick={this._deleteFile}
                >
                  <img
                    src={require("../../../assets/img/close.png")}
                    alt="deletePreview"
                  />
                </Button>
              </div>
            ) : (
              <Label
                className="uploadDocument"
                for="uploadFile_add_expense_modal"
                onClick={() =>
                  !isRegularUser() && this._toggleUpgradeAccountModal(true)
                }
              >
                <Input
                  type="file"
                  id="uploadFile_add_expense_modal"
                  accept="image/x-png,image/gif,image/jpeg,.pdf"
                  value=""
                  disabled={loading}
                  onChange={(event) => this._updateFile(event)}
                />
                <img
                  src={
                    formFields.receipt.file.type === "image"
                      ? formFields.receipt.file.previewBlob
                      : require("../../../assets/img/uploadIcon.png")
                  }
                  alt="file upload"
                />
                {!loading && formFields.receipt.file.previewBlob && (
                  <Button onClick={this._deleteFile} className="deletePreview">
                    <i className="fa fa-times" />
                  </Button>
                )}

                <div className="uploadLabel">
                  <h4>
                    {formFields.receipt.file.uploadData &&
                      formFields.receipt.file.uploadData.name
                      ? formFields.receipt.file.uploadData.name
                      : "Upload Receipt"}
                  </h4>
                  <p>File size must be less than 5mb</p>
                </div>
              </Label>
            )}
            <CustomInput
              type="checkbox"
              className="mb-3 mt-3"
              id={`isShowRecurringFrequency__addExpenceAgent`}
              label="Recurring Expense"
              checked={formFields.isShowRecurringFrequency.value}
              onChange={(event) =>
                this._onChangeFormField(
                  "isShowRecurringFrequency",
                  event.target.checked
                )
              }
              disabled={data?._id || isPastDate(formFields?.date?.value)}
            />

            {formFields.isShowRecurringFrequency.value ? (
              <FormGroup className="floatingLabel custom-select-wrapper">
                <Input
                  type="select"
                  value={formFields.recurringFrequency.value}
                  onChange={(event) =>
                    this._onChangeFormField(
                      "recurringFrequency",
                      event.target.value
                    )
                  }
                  disabled={data?._id}
                >
                  <option value="">Select</option>
                  {recurringFrequencyConfig.map((rf) => (
                    <option key={rf.value} value={rf.value}>
                      {rf.label}
                    </option>
                  ))}
                </Input>
                <Label>Recurring Frequency</Label>
                {formFields.recurringFrequency.error && (
                  <div className="validation-error">
                    {formFields.recurringFrequency.error}
                  </div>
                )}
              </FormGroup>
            ) : null}
            <FormGroup className="floatingLabel">
              <Input
                type="textarea"
                rows="3"
                value={formFields.notes.value}
                name="notes"
                onChange={(event) =>
                  this._onChangeFormField("notes", event.target.value)
                }
                placeholder=" "
              />
              <Label>Notes</Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              size="lg"
              outline
              onClick={this._closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="lg"
              disabled={loading}
              onClick={this._onSubmit}
            >
              {data?._id ? "Update" : "Save"}
            </Button>
          </ModalFooter>
          {loading && (
            <div className="table-overlay">
              <div>
                <i className="fa fa-spinner fa-spin" />
              </div>
            </div>
          )}
        </Modal>
        {/* upgrade account modal */}
        <UpgradeAccountModal
          {...this.props}
          isOpen={upgradeAccountModal.isOpen}
          toggle={this._toggleUpgradeAccountModal}
        />
      </>
    );
  }
}

export default AddExpense;
