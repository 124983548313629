import React, { useEffect, useState } from "react";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  capitalize,
  convertIsoToUtc,
  errorHandler,
  formatCurrencyValue,
  formatOnlyDateMoment,
  getLoggedInUserId,
  isRegularUser,
  openUrlOnNewTab,
  structureQueryParams,
} from "../../../helper-methods";
import {
  agentGetProfitAndLoss,
  signingCompanyProfitAndLoss,
} from "../../../http/http-calls";
import { BASE_URL } from "../../../config";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Collapse,
  FormGroup,
  Label,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useSelector } from "react-redux";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import SkeletonLoading from "../components/Skeletons/SkeletonLoading";
import useScreenWidth from "../components/HelpModule/useScreenWidth";
import SvgIcons from "../components/SvgIcons";

const DEFAULT_KEY_FOR_COMPANY = "signingcompany";

const AgentCompanyProfitLoss = () => {
  const userType = useSelector((state) => state?.userData?.type);

  const { isForMobile } = useScreenWidth();

  const [isClassAdded, setIsClassAdded] = useState(false);

  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [netIncome, setNetIncome] = useState(0);
  const [isOpenCollapse, setIsOpenCollapse] = useState("income");
  const [upgradeAccountModal, setUpgradeAccountModal] = useState({
    isOpen: false,
    data: null,
  });

  const filterShowMobile = () => {
    setIsClassAdded((prevState) => !prevState);
  };

  const _getAllProfitLossReport = async (newPayload) => {
    try {
      setLoading(true);

      const filterPayload = {};

      if (dateRangeValue) {
        filterPayload["startDate"] = dateRangeValue[0];
        filterPayload["endDate"] = dateRangeValue[1];
      }

      const payload = {
        filters: filterPayload,
      };

      let response;
      if (userType === DEFAULT_KEY_FOR_COMPANY) {
        response = await signingCompanyProfitAndLoss(
          newPayload ? newPayload : payload
        );
      } else {
        response = await agentGetProfitAndLoss(
          newPayload ? newPayload : payload
        );
      }
      setIncomes(response?.incomes || []);
      setExpenses(response?.expenses || []);
      setTotalIncome(response?.totalIncome || 0);
      setTotalExpense(response?.totalExpense || 0);
      setNetIncome(response?.netIncome || 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _setFilterDataFromLocalStorage = () => {
    if (userType === DEFAULT_KEY_FOR_COMPANY) {
      if (localStorage && localStorage.companyprofitloss) {
        try {
          const filters = JSON.parse(localStorage.companyprofitloss);
          let dateRange = [
            new Date(convertIsoToUtc(filters.dateRangeValue[0])),
            new Date(convertIsoToUtc(filters.dateRangeValue[1])),
          ];
          setDateRangeValue(dateRange);
          _getAllProfitLossReport(); // get all data from company side
        } catch (e) {
          _getAllProfitLossReport();
        }
      } else {
        _getAllProfitLossReport();
      }
    } else {
      if (localStorage && localStorage.agentprofitloss) {
        try {
          const filters = JSON.parse(localStorage.agentprofitloss);
          let dateRange = [
            new Date(convertIsoToUtc(filters.dateRangeValue[0])),
            new Date(convertIsoToUtc(filters.dateRangeValue[1])),
          ];
          setDateRangeValue(dateRange);
          _getAllProfitLossReport(); // get all data from agent side
        } catch (e) {
          _getAllProfitLossReport();
        }
      } else {
        _getAllProfitLossReport();
      }
    }
  };

  //For download pdf agent and company
  const _downloadData = (action = "download") => {
    const payload = {
      userId: getLoggedInUserId(),
    };

    if (dateRangeValue) {
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      if (userType === DEFAULT_KEY_FOR_COMPANY) {
        openUrlOnNewTab(
          `${BASE_URL}/${userType}/download/profitloss${queryParams}`
        );
      } else {
        openUrlOnNewTab(
          `${BASE_URL}/${userType}/download/profit-loss${queryParams}`
        );
      }
  };

  //handle localstorage for agent and company
  const _persistFilter = () => {
    if (dateRangeValue !== null) {
      let data = { dateRangeValue };
      if (userType === DEFAULT_KEY_FOR_COMPANY) {
        localStorage.companyprofitloss = JSON.stringify(data);
      } else {
        localStorage.agentprofitloss = JSON.stringify(dateRangeValue);
      }
    } else {
      if (userType === DEFAULT_KEY_FOR_COMPANY) {
        delete localStorage.companyprofitloss;
      } else {
        delete localStorage.agentprofitloss;
      }
    }
  };

  const _onChangeDatePicker = (newDateRangeValue) => {
    setDateRangeValue(newDateRangeValue);
    _persistFilter();
    const filterPayload = {};

    if (newDateRangeValue !== null) {
      filterPayload["startDate"] = newDateRangeValue[0];
      filterPayload["endDate"] = newDateRangeValue[1];
      _getAllProfitLossReport({ filters: filterPayload });
    } else {
      _getAllProfitLossReport({ filters: {} });
    }
  };

  const _toggleCollapse = (isOpen = false) => {
    setIsOpenCollapse((prevIsOpen) => (isOpen !== prevIsOpen ? isOpen : false));
  };

  const _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    setUpgradeAccountModal({
      isOpen,
      data,
    });
  };

  useEffect(() => {
    _setFilterDataFromLocalStorage(); //handle data localstorage from filters
    HeaderEventEmitter.subscribe("download-profit-loss", () => {
      _downloadData();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <h2>Profit & Loss</h2>

          <div className="rightSide">
            <Button
              className="filterBarIcon"
              color="link"
              onClick={filterShowMobile}
            >
              <SvgIcons type={"filterIcon"} />
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={filterShowMobile}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>
        <div
          className={`filterContainer  responsiveFilter ${isClassAdded ? "show" : ""
            }`}
        >
          <div className="filterIcon">
            <SvgIcons type={"filterIcon"} />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={filterShowMobile}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup>
              <Label>Date Range</Label>
              <DateRangePicker
                className="dateRange"
                format="MM-dd-y"
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                onChange={(dateRangeValue) =>
                  userType === DEFAULT_KEY_FOR_COMPANY
                    ? _onChangeDatePicker(dateRangeValue)
                    : isRegularUser()
                      ? _onChangeDatePicker(dateRangeValue)
                      : _toggleUpgradeAccountModal(true)
                }
                value={dateRangeValue}
              />
            </FormGroup>

            <Button
              color="link"
              outline
              className="downloadButton ml-auto"
              onClick={() =>
                userType === DEFAULT_KEY_FOR_COMPANY
                  ? _downloadData()
                  : isRegularUser()
                    ? _downloadData()
                    : _toggleUpgradeAccountModal(true)
              }
            >
              Download
              <SvgIcons type={"download"} />
            </Button>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        <div className="downloadButtonPos">
          <Button
            color="link"
            outline
            onClick={() =>
              userType === DEFAULT_KEY_FOR_COMPANY
                ? _downloadData()
                : isRegularUser()
                  ? _downloadData()
                  : _toggleUpgradeAccountModal(true)
            }
          >
            Download
            <SvgIcons type={"download"} />
          </Button>
        </div>

        {/* Net Income card */}
        {loading ? (
          <div className="mb-3">
            <SkeletonLoading
              type="card"
              count={1}
              height={isForMobile ? 50 : 50}
              width={isForMobile ? 350 : 1500}
            />
          </div>
        ) : (
          <Card className="profitLossCard netIncome">
            <CardHeader>
              <p className="income">Net Income</p>
              <p className="income">
                {netIncome ? formatCurrencyValue(netIncome) : "$0"}
              </p>
            </CardHeader>
          </Card>
        )}

        {/* Income detail card */}
        {loading ? (
          <div className="mb-3">
            <SkeletonLoading
              type="card"
              count={1}
              height={isForMobile ? 50 : 50}
              width={isForMobile ? 350 : 1500}
            />
          </div>
        ) : (
          <Card className="profitLossCard">
            <CardHeader>
              <CardTitle tag="h6">Income</CardTitle>
              <div>
                {isOpenCollapse === "income" ? (
                  ""
                ) : (
                  <span className="income">
                    {totalIncome ? formatCurrencyValue(totalIncome) : "$0"}
                  </span>
                )}

                <Button
                  color="link"
                  className={`${isOpenCollapse === "income" ? "show" : null}`}
                  onClick={() => _toggleCollapse("income")}
                >
                  <img
                    src={
                      require("../../../assets/img/arrow-dropdown.svg").default
                    }
                    alt="arrow"
                  />
                </Button>
              </div>
            </CardHeader>
            <Collapse
              className="profitCollapse"
              isOpen={isOpenCollapse === "income"}
            >
              <CardBody className="p-0">
                <ul className="profitLossReport">
                  {incomes.length !== 0 ? (
                    incomes.map((each, index) => {
                      return (
                        <li key={index}>
                          {userType === DEFAULT_KEY_FOR_COMPANY
                            ? each.name.full
                            : each._signingCompany?.companyName
                              ? capitalize(each._signingCompany.companyName)
                              : each._client?.name
                                ? capitalize(each._client.name.full)
                                : each.name
                                  ? capitalize(each.name)
                                  : "N/A"}
                          <span>
                            {each.amount
                              ? formatCurrencyValue(each.amount)
                              : "$0"}
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <li className="text-center">There is no data to display</li>
                  )}
                </ul>
              </CardBody>
              <CardFooter>
                <p className="income">Total Income</p>
                <p className="income">
                  {totalIncome ? formatCurrencyValue(totalIncome) : "$0"}
                </p>
              </CardFooter>
            </Collapse>
          </Card>
        )}

        {loading ? (
          <div className="mb-3">
            <SkeletonLoading
              type="card"
              count={1}
              height={isForMobile ? 50 : 50}
              width={isForMobile ? 350 : 1500}
            />
          </div>
        ) : (
          <Card className="profitLossCard">
            <CardHeader>
              <CardTitle tag="h6">Expenses</CardTitle>
              <div>
                {isOpenCollapse === "expenses" ? (
                  ""
                ) : (
                  <span className="income">
                    {totalExpense ? formatCurrencyValue(totalExpense) : "$0"}
                  </span>
                )}

                <Button
                  color="link"
                  className={`${isOpenCollapse === "expenses" ? "show" : null}`}
                  onClick={() => _toggleCollapse("expenses")}
                >
                  <img
                    src={
                      require("../../../assets/img/arrow-dropdown.svg").default
                    }
                    alt="arrow"
                  />
                </Button>
              </div>
            </CardHeader>
            <Collapse isOpen={isOpenCollapse === "expenses"}>
              <CardBody className="p-0">
                <ul className="profitLossReport">
                  {expenses.length !== 0 ? (
                    expenses.map((each, index) => {
                      return (
                        <li key={index}>
                          {each.item}
                          <span>
                            {each.amount
                              ? formatCurrencyValue(each.amount)
                              : "$0"}
                          </span>
                        </li>
                      );
                    })
                  ) : loading ? (
                    <i className="fa fa-spinner fa-spin mr-1" />
                  ) : (
                    <li className="text-center">There is no data to display</li>
                  )}
                </ul>
              </CardBody>
              <CardFooter>
                <p className="income">Total Expenses</p>
                <p className="income">
                  {" "}
                  {totalExpense ? formatCurrencyValue(totalExpense) : "$0"}
                </p>
              </CardFooter>
            </Collapse>
          </Card>
        )}
        {upgradeAccountModal.isOpen && (
          <UpgradeAccountModal
            isOpen={upgradeAccountModal.isOpen}
            toggle={_toggleUpgradeAccountModal}
          />
        )}
      </div>
    </>
  );
};

export default AgentCompanyProfitLoss;
