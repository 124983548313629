import React, { Suspense, lazy, useCallback } from "react";
import { Card, CardHeader, Col, CustomInput, Row } from "reactstrap";
import SkeletonLoading from "./Skeletons/SkeletonLoading";
const CustomPagination = lazy(() => import("./CustomPagination"));

const CustomCard = ({
  className = "",
  pageNumber,
  pageSize = 10,
  tableData = [],
  headerKeys,
  totalCount,
  onPaginate,
  rowSelection = false,
  selectedRows = [],
  setSelectedRows,
  isPageStartFromOne = false,
  isPagination = true,
  cardHeaderFormat,
  cardDataFormat,
  columnSizeMD,
  showTableLoading,
}) => {
  const isRowSelected = useCallback(
    (eachRow) => {
      if (!headerKeys?.length || !selectedRows?.length) return false;

      return selectedRows?.find(
        (eachSelect) =>
          eachSelect[headerKeys?.[0]?.id] === eachRow[headerKeys?.[0]?.id]
      )
        ? true
        : false;
    },
    [selectedRows, headerKeys]
  );

  const _setSelectedRows = (isChecked, row) => {
    try {
      if (!setSelectedRows) return;

      if (row === "all") {
        if (isChecked) {
          setSelectedRows(tableData);
        } else {
          setSelectedRows();
        }
      } else {
        if (isChecked) {
          const newSelectedRows = [...selectedRows];
          const findRow = newSelectedRows?.find(
            (each) => each[headerKeys?.[0]?.id] === row[headerKeys?.[0]?.id]
          );
          if (!findRow) {
            newSelectedRows.push(row);
            setSelectedRows(newSelectedRows);
          }
        } else {
          const newSelectedRows = [...selectedRows];
          const findRowIndex = newSelectedRows?.findIndex(
            (each) => each[headerKeys?.[0]?.id] === row[headerKeys?.[0]?.id]
          );
          if (findRowIndex >= 0) {
            newSelectedRows.splice(findRowIndex, 1);
            setSelectedRows(newSelectedRows);
          }
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <>
      <Row>
        {tableData?.length ? (
          tableData?.map((eachRow, rowIndex) => (
            <Col
              key={`customCardCol_${rowIndex}`}
              md={columnSizeMD ? columnSizeMD : 6}
              className="mb-4 mb-lg-0"
            >
              <Card
                key={`customCard_${rowIndex}`}
                className="responsiveTableCard"
              >
                {headerKeys?.length > 0 &&
                  headerKeys?.map(
                    (eachHeading, headingIndex) =>
                      eachHeading.id !== "id" &&
                      eachHeading.id !== "_id" && (
                        <CardHeader>
                          {rowSelection && (
                            <div className="checkBoxColumn">
                              <CustomInput
                                id={`row_selection_${eachRow[headerKeys?.[0]?.id] || rowIndex
                                  }`}
                                type="checkbox"
                                onChange={(e) =>
                                  _setSelectedRows(e.target.checked, eachRow)
                                }
                                checked={isRowSelected(eachRow)}
                              />
                            </div>
                          )}
                          {cardHeaderFormat
                            ? cardHeaderFormat(
                              eachRow[eachHeading.id],
                              eachRow,
                              eachHeading.id
                            )
                            : eachRow[eachHeading.id]}
                        </CardHeader>
                      )
                  )}
                {cardDataFormat ? cardDataFormat(eachRow) : "N/A"}
              </Card>
            </Col>
          ))
        ) : showTableLoading ? (
          <SkeletonLoading type="card" count={4} height={480} width={370} />
        ) : (
          <Col>
            <Card>
              <div className="nodata">
                <img
                  src={require("../../../assets/img/noData.svg").default}
                  alt="no data"
                />
                <p>There is no data to display</p>
              </div>
            </Card>
          </Col>
        )}
      </Row>
      {isPagination ? (
        <Suspense fallback={<></>}>
          <CustomPagination
            data={tableData}
            dataCount={totalCount}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={(pageNumber) => onPaginate(pageNumber, pageSize)}
            isPageStartFromOne={isPageStartFromOne}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default CustomCard;
